import React from 'react'
import Footer3 from '../components/footer3';
import Navbar from '../components/navbar';
import PageHeader from '../components/pageHeader';
import SignUpComponent from '../components/SignUpComponent';

class SignUpPage extends React.Component{
    render(){
        return(
            <div>
                <Navbar/>
                <PageHeader title=""/>
                <SignUpComponent />
                <Footer3 />
            </div>
        );
    }

}

export default SignUpPage;