import React from 'react';
import axios from "axios";

class SignInComponent extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
        email: '',
        password: '',
        logged: false
    };
    this.handleChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    // console.log('props:');
    // console.log(props);

    if (props.action == 'logout') {

      this.setState({ logged: false })
      localStorage.setItem('elab_auth',null)
    }

  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    async getData(){
  }

  handleSubmit(event)  {
    const req_data = {
      email: this.state.email,
      password: this.state.password
    }
    this.state.auth_key = ''
    axios({
      method: "POST",
      url: process.env.REACT_APP_BACKEND_URL + '/user/signin',
      data: req_data,
    }).then(res => {
      this.setState({ data: res.data })
      // this.state.data = res.data
      if (res.data.status == 1) {
        localStorage.setItem('elab_auth',res.data.auth_key)
        this.setState({ logged: true })
        
      }
      
      console.log(this.state.data)

      console.log('this.state.logged = ' + this.state.logged)

    }).catch(function (error) {
        console.log(error);
    });
    event.preventDefault();
  }

  render(){

    return (
    <div>
      { this.state.logged ? 
      
        <section className="section" data-background="images/backgrounds/bg-dots.png">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="section-title">Авторизация прошла успешно!</h4>
              </div>
            </div>
          </div>          
        </section> 
      
      :        
      
      <section className="section" data-background="images/backgrounds/bg-dots.png">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="section-title"></h2>
            </div>
            <div className="col-lg-8 mx-auto">
              <div className="bg-white rounded text-center p-5 shadow-down">
                <h4 className="mb-80">АВТОРИЗАЦИЯ</h4>
                <form  className="row">

                  <div className="col-md-12">
                    <input type="email" name="email"  onChange = {this.onChange} value = {this.state.email} placeholder="Email" className="form-control px-0 mb-4" />
                  </div>

                  <div className="col-12">
                    <input type="password"   onChange = {this.onChange} value = {this.state.password}  name="password" placeholder="Пароль" className="form-control px-0 mb-4" />
                  </div>

                  <div className="col-lg-6 col-10 mx-auto mt-5">
                    <button  onClick = {this.handleSubmit} type='submit' className="btn btn-primary w-100">Отправить</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </section>  }
    </div>
    );
  }
};
export default SignInComponent;