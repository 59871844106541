import React, {useEffect, useState} from 'react'
import Coursenav from '../components/coursenav';
import Courseitem from '../components/courseitem';
import Navbar from '../components/navbar';
import Footer2 from '../components/Footer2';
import PageHeader from '../components/pageHeader';
import OurPartners from '../components/ourPartners';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import { Translation, Trans } from "react-i18next";


function CoursesList({onLanguageChange}) {

  const { i18n } = useTranslation(); 
  const [event, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState(i18n.language);

  
  useEffect(() => {
    fetchData();
  },[i18n.language]);

  const fetchData = () => {
    setLoading(true);

    var langs = ['en','ru'];
    var lang = i18n.language;
    
    lang = lang.substring(1,2).toLowerCase();

    if (!langs.includes(lang)) {
      lang = langs[0];
    }

    axios({
      method: "GET",
      url: process.env.REACT_APP_BACKEND_URL+'/api/'+lang+'/get-courses',
    }).then(response => {
      setData(response.data);
      setLoading(false);
    })
  };

  return(
<div>
    
  <Navbar onLanguageChange={onLanguageChange}/>

  <PageHeader/>

  <section className="section">
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
        </div>
      </div>

      <div className="row shuffle-wrapper">
        {event.map(post =>( <Courseitem item={post} key={post.id}/> ))}
      </div>
    </div>
  </section>

  <OurPartners />

  <Footer2 />

</div>
  );
    
}


export default withTranslation()(CoursesList);
