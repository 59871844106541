import React, {useEffect, useState} from 'react'
import Coursenav from '../components/coursenav';
import Courseitem from '../components/courseitem';
import Navbar from '../components/navbar';
import Footer2 from '../components/Footer2';
import PageHeader from '../components/pageHeader';
import OurPartners from '../components/ourPartners';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';


function CoursesListGroup({onLanguageChange}) {

  const match = useParams();
  const [event, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const { i18n } = useTranslation();
  const group = match.group;

  useEffect(() => {
    fetchData();
  }, [i18n.language, group]);

  const fetchData = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: process.env.REACT_APP_BACKEND_URL+'/api/'+i18n.language+'/get-group-courses/'+group,
    }).then(response => {
      setData(response.data);
      setLoading(false);
    })
  };

  return(
<div>
    
  <Navbar onLanguageChange={onLanguageChange}/>

  <PageHeader/>

  <section className="section">
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
          <Coursenav/>
        </div>
      </div>

      <div className="row shuffle-wrapper">
        {event.map(post =>( <Courseitem item={post} key={post.id}/> ))}
      </div>
    </div>
  </section>

  <OurPartners />

  <Footer2 />

</div>
  );
    
}


export default CoursesListGroup;
