import React from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";

class SignInComponent extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
        name: '', 
        email: '',
        password: ''
    };
    this.handleChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    async getData(){
  }

  handleSubmit(event)  {

    console.log(this.state.name)
   
    axios.post(process.env.REACT_APP_BACKEND_URL+'/user/signup',
      {
        username: this.state.name,
        email: this.state.email,
        password: this.state.password
      }).then(res => {
        this.state.data = res.data
        if (this.state.data.status==1) {
          alert('Регистрация прошла успешно!')
          window.location = "/signin"          
        } else {
          alert('При регистрации произошла ошибка! Возможно пользователь с таким именем или электронной почтой уже существует!');
        }
        console.log(this.state.data)
      }).catch(function (error) {
        console.log(error);
      });

    event.preventDefault();
  }

  render(){

    return (
    <div>
      <section className="section" data-background="images/backgrounds/bg-dots.png">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="section-title"></h2>
            </div>
            <div className="col-lg-8 mx-auto">
              <div className="bg-white rounded text-center p-5 shadow-down">
                <h4 className="mb-80">РЕГИСТРАЦИЯ</h4>
                <form  className="row">

                  <div className="col-md-12">
                    <input type="text" name="name"  onChange = {this.onChange} value = {this.state.name} placeholder="Имя" className="form-control px-0 mb-4" />
                  </div>

                  <div className="col-md-12">
                    <input type="email" name="email"  onChange = {this.onChange} value = {this.state.email} placeholder="Email" className="form-control px-0 mb-4" />
                  </div>

                  <div className="col-12">
                    <input type="password"   onChange = {this.onChange} value = {this.state.password}  name="password" placeholder="Пароль" className="form-control px-0 mb-4" />
                  </div>

                  <div className="col-lg-6 col-10 mx-auto mt-5">
                    <button  onClick = {this.handleSubmit} type='submit' className="btn btn-primary w-100">Отправить</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>    
    </div>
    );
  }
};
export default SignInComponent;