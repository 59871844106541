import React, { useState, useEffect } from 'react';
import axios from "axios";
import { CommentSection } from 'react-comments-section'
import 'react-comments-section/dist/index.css'

const Comments=(props)=>{

  const course_id = props.course_id;
  const auth_key = localStorage.getItem('elab_auth');
  
  const [currentUser, setCurrentUser] = useState(null)
  const [data, setData] = useState([])

  // const [loading, setLoading] = React.useState(false)
  // const [submiting, setSubmiting] = React.useState(false)
  // const [loadingData, setLoadingData] = React.useState(false)
 

  console.log('auth_key = ' + auth_key)
  console.log('course_id = ' + course_id)

  useEffect(() => {

    // get user
    const fetchUser = async () => {
      try {
        console.log('fetchUser inner auth_key = ' + auth_key)
        axios({
          method: "POST",
          url: process.env.REACT_APP_BACKEND_URL + '/user/getuser',
          data: { 'auth_key': auth_key },
        }).then(res => {

          console.log('get user')
          console.log('res.data.status = ' + res.data.status)

          if (res.data.status == 0) {
            
            localStorage.setItem('elab_auth', null)
           
          } else {

            setCurrentUser( {
              currentUserId: res.data.user.id,
              currentUserFullName: res.data.user.username,
              currentUserToken: auth_key,
              // currentUserImg: '/images/avatar.png',
              // currentUserImg: 'http://localhost:3000/images/avatar.png',
              currentUserProfile: '/',
              currentUserImg: '/images/avatar.png'
            } )
          }

         
        }).catch(function (error) {
            console.log(error);
        });
      } catch (error) {
        console.error(error)
      }
    };


    const fetchData = async () => {
      try {
        const request = { 'post_id': course_id }

        // console.log('get data Start ' + 'post_id=' + course_id)
        // console.log(request)

        axios({
          method: "POST",
          url: process.env.REACT_APP_BACKEND_URL + '/comment/get',
          data: request,
        }).then(res => {  
          // console.log('get data done')
          // console.log(res.data)
          setData(res.data)
        }).catch(function (error) {
          console.log(error);
        }); 
      } catch (error) {
        console.error(error)
      }
    }


    fetchData();

    console.log('fetchUser auth_key = ' + auth_key)
    if ((auth_key!=undefined) && (auth_key!=null)) {
      fetchUser();
    }
    
    
  }, []);

  return <div>

      {currentUser!=null && currentUser!='null' ?

      (<CommentSection
        currentUser={currentUser}

        commentData={data}
        placeholder={"Ваш комментарий..."}

        onReplyAction={(data) => {
          console.log(data)
        }}
        
        onSubmitAction={(data) => {
          const submit_data = {
            post_id: course_id,
            user_id: currentUser.currentUserId,
            auth_key: auth_key,
            text: data.text,
            com_id: data.comId,
          }

          console.log(submit_data)

          axios({
            method: "POST",
            url: process.env.REACT_APP_BACKEND_URL + '/comment/add',
            data: submit_data,
          }).then(res => {
            
            // console.log(res)
      
          }).catch(function (error) {
              console.log(error);
          });    

          console.log('check submit, ', data)
        }}

        currentData={(data) => {
          console.log('current data', data)
        }}
        
      />)
      
      :

      (<div>
        
      <CommentSection
        currentUser={null}
        logIn={{
          onLogin: ()=>window.location='/signin',
          onSignUp: ()=>window.location='/signup',
        }}

        commentData={data}
        placeholder={"Ваш комментарий..."}

        onSubmitAction={(data) => {
          console.log('check submit, ', data)
        }}

        // currentData={(data) => {
        //   console.log('current data', data)
        // }}

        replyInputStyle={{ display: "none" }}
      />
      
      </div>)
      }      

  </div>
}

export default Comments;