import './App.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Home from './pages/home';
import About from './pages/about';
import PostList from './pages/postList';
import PostDetail from './pages/postDetail';
import CoursesList from './pages/coursesList';
import CoursesListGroup from './pages/coursesListGroup';
import Contact from './pages/contact';
import CourseDetail from './pages/courseDetail';
import Vum from './pages/vum';
import Freecourse from './pages/freecourse';
import ITCourseDetail from "./pages/ITCourseDetail";
import TeachersCourseDetail from "./pages/TeachersCourseDetail";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";

function App() {

  // const [language, setLanguage] = useState([]);

  const [language, setLanguage] = useState([]);

  const { i18n } = useTranslation();
  const app = this;

  useEffect(() => {
    document.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
    // document.title = i18n.t("document_title");
  }, [i18n, i18n.language]);

  function changeLang(lang) {
    i18n.changeLanguage(lang)
    setLanguage(lang);
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home onLanguageChange={(lang) => i18n.changeLanguage(lang) }/>} />
        <Route exact path="/about" element={<About onLanguageChange={(lang) => i18n.changeLanguage(lang) }/>} />
        <Route exact path="/vum" element={<Vum onLanguageChange={(lang) => i18n.changeLanguage(lang) }/>} />
        <Route exact path="/freecourse" element={<Freecourse onLanguageChange={(lang) => i18n.changeLanguage(lang) }/>} />

        <Route exact path="/post-list" element={<PostList onLanguageChange={(lang) => i18n.changeLanguage(lang) }/>} />
        <Route exact path="/post-detail/:id" element={({match}) => <PostDetail onLanguageChange={(lang) => i18n.changeLanguage(lang) } match={match} />} />

        <Route exact path="/courses-list" element={<CoursesList onLanguageChange={ (lang) => changeLang(lang) }/> } />
        <Route exact path="/courses-list-group/:group" element={<CoursesListGroup onLanguageChange={(lang) => i18n.changeLanguage(lang)} />} />
        <Route exact path="/course-detail/:id" element={ <CourseDetail onLanguageChange={(lang) => i18n.changeLanguage(lang) } />} />

        
        <Route exact path="/it-course-detail/:id" element={({match}) => <ITCourseDetail onLanguageChange={(lang) => i18n.changeLanguage(lang) } match={match}/>} />
        <Route exact path="/teacher-course-detail/:id" element={({match}) => <TeachersCourseDetail onLanguageChange={(lang) => i18n.changeLanguage(lang) } match={match}/>} />

        <Route exact path="/contact" element={() => <Contact onLanguageChange={(lang) => i18n.changeLanguage(lang) }/>} />

        <Route exact path="/signin" element={<SignInPage onLanguageChange={(lang) => i18n.changeLanguage(lang)}/>} />
        <Route exact path="/signup" element={<SignUpPage onLanguageChange={(lang) => i18n.changeLanguage(lang)}/>} />
        <Route exact path="/logout" element={<SignInPage onLanguageChange={(lang) => i18n.changeLanguage(lang)} action={'logout'}/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
