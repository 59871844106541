
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Helmet} from "react-helmet";
import Navbar from '../components/navbar';
import PageHeader from '../components/pageHeader';
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import Footer2 from '../components/Footer2';
import OurPartners from '../components/ourPartners';
import Comments from '../components/Comments';

function CourseDetail({onLanguageChange}) {

  const match = useParams();

  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(false);
  const id = match.id;
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } }); 
  const { i18n } = useTranslation();

  useEffect(() => {
    fetchData(id);
  }, [id, i18n.language]);

  const fetchData = (id) => {    
    setLoading(true);
    axios({
      method: "GET",
      url: process.env.REACT_APP_BACKEND_URL+'/api/'+i18n.language+'/get-course/'+id,
    }).then(response => {
      setPost(response.data);
      setLoading(false);
    })
  };  


  return(
<div>
<Helmet>
      <title>{post.name}</title>
      <meta name="description" content="{post.descr}" />
      <meta name="og:title" content="{post.name}" />
      <meta name="og:description" content="{post.descr}" />
      <meta name="og:image" content="{post.image}" />
  </Helmet>

  <Navbar onLanguageChange={onLanguageChange}/>

  <PageHeader/>

  <section className="section">
    <div className="container">

      <div className="row">

        <div className="col-12">

          <div style={{color: 'black', fontWeight:'bold', fontSize:'36px', textAlign:"center", paddingBottom:"20px"}}>{post.name}</div>

          <img style={{marginRight:'auto', marginLeft:'auto', display:'block'}} src={post.image} className="rounded d-block"/>

          <div>{renderHTML(post.content)}</div>

        </div>

      
      </div>

    </div>
  </section>


  { post.id!=undefined ? <Comments course_id={post.id}/> : "" }

  <OurPartners />

  <Footer2 />
</div>
  );
    

    
}


export default withTranslation()(CourseDetail);
