import React from 'react'
import Footer3 from '../components/footer3';
import Navbar from '../components/navbar';
import PageHeader from '../components/pageHeader';
import SignInComponent from '../components/SignInComponent';

class SignInPage extends React.Component{

    constructor(props) {
        super(props);
        console.log(props);
        if (props.action == 'logout') {
            localStorage.setItem('elab_auth',null)
        }
    }
    render(){
        return(
            <div>
                <Navbar/>
                <PageHeader title=""/>
                <SignInComponent  />
                <Footer3 />
            </div>
        );
    }

}

export default SignInPage;