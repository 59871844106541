import React from 'react'
import axios from "axios";
import {Form} from 'react-bootstrap'
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

class Footer3 extends React.Component{

 
    render(){
        return(
            <div>
           

<footer className="bg-dark footer-section">
  <div className="section">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <h5 className="text-light">Email</h5>
          <p className="text-white paragraph-lg font-secondary">elab.shakha@gmail.com</p>
          <p className="text-white paragraph-lg font-secondary">elab.samirr@gmail.com</p>
        </div>
        <div className="col-md-4">
          <h5 className="text-light">Контактные данные</h5>
          <p className="text-white paragraph-lg font-secondary">+7 (776) 149 2925</p>
          <p className="text-white paragraph-lg font-secondary">+7 (705) 319 9185</p>
        </div>
        <div className="col-md-4">
          <h5 className="text-light">Адрес</h5>
          <p className="text-white paragraph-lg font-secondary">Казахстан, г. Алматы, Ауэзовский район, Аксай 5, Дом 25</p>
        </div>
        <div className="col-md-4">
          <h5 className="text-light">Мы в Соц.сетях: </h5>
          <a className="text-white" href="https://www.youtube.com/channel/UCjwLOs7tgaZ1ArqTv10r8Zw" target="_blank"><i className="ti-youtube"></i></a>
          <a href="https://www.youtube.com/channel/UCjwLOs7tgaZ1ArqTv10r8Zw" target="_blank" className="text-white paragraph-lg font-secondary"> <u>Youtube</u><br/></a>
          <a className="text-white" href="https://www.instagram.com/elab.asia/" target="_blank" ><i className="ti-instagram"></i></a>
          <a href="https://www.instagram.com/elab.asia/" target="_blank" className="text-white paragraph-lg font-secondary"> <u>Instagram</u></a>
        </div>
      </div>
    </div>
  </div>
  <div className="border-top text-center border-dark py-5">
    <p className="mb-0 text-light">Copyright ©<script>
        var CurrentYear = new Date().getFullYear()
        document.write(CurrentYear)
      </script> by <a href="/">elab.asia</a></p>
  </div>
</footer>

</div>

)
    }
}

export default Footer3;